<template>
  <div class="checkIntroduce w">
    <div class="page_title">
      查看简历
    </div>
    <div class="list_box">
      <introduceComList itemType="send" :lists="list_data.lists" :numberRow="2" v-if="list_data.lists.length"></introduceComList>
      <el-empty :image-size="200" v-else></el-empty>
    </div>
    <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
  </div>
</template>

<script>
import introduceComList from '@/components/introduce/introduceComList.vue';
import Pager from '@/components/pager/Pager.vue'
import mixin from '@/mixin/index'
export default {
  mixins: [mixin],
  components: {
    introduceComList,
    Pager,
  },
  data () {
    return {
      id: ''
    }
  },
  created () {
    this.id = this.$route.query.id;
    this.getData();
  },
  computed: {
  },
  methods: {
    getData () {
      this.getList('getResumeSendList', this.page, { job_id: this.id });
    }
  },

}
</script>

<style lang="scss">
.checkIntroduce {
  padding: 26px 35px 92px;
  background-color: #fff;
  margin: 18px auto 69px !important;
  border-radius: 8px 8px 8px 8px;
  .page_title {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    margin-bottom: 13px;
  }

  .list_box {
    .lists_total_num_box {
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      margin: 24px 0 29px;
      span {
        color: #3f63ff;
      }
    }
  }
}
</style>